import React from 'react';

import './App.css';

// @ts-ignore
import ComingSoon from './assets/png/web-comingSoon.png';

function App() {
  return (
    <img className="App" src={ComingSoon} alt={'coming soon'}/>
  )
}

export default App
